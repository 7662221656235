const state = {
  Manager: null,
  Affiliate: null,
  User: null,
  Customer: null,
};

const getters = {
  Manager: (state) => state.Manager,
  Affiliate: (state) => state.Affiliate,
  User: (state) => state.User,
  Customer: (state) => state.Customer,
  isManagerLoggedIn: () => state.Manager != null,
  isAffiliateLoggedIn: () => state.Affiliate != null,
  isUserLoggedIn: () => state.User != null,
  isCustomerLoggedIn: () => state.Customer != null,
};

const mutations = {
  setManager(state, data) {
    state.Manager = data;
  },

  setAffiliate(state, data) {
    state.Affiliate = data;
  },

  setUser(state, data) {
    state.User = data;
  },

  setCustomer(state, data) {
    state.Customer = data;
  },
};

const actions = {
  setManager({ commit }, manager) {
    commit('setManager', manager);
  },

  setAffiliate({ commit }, affiliate) {
    commit('setAffiliate', affiliate);
  },

  setUser({ commit }, user) {
    commit('setUser', user);
  },

  setCustomer({ commit }, customer) {
    commit('setCustomer', customer);
  },

  async registerUser(ctx, user_details) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: {
          success,
          message,
          data: { user },
        },
      } = await axios.post('/user/register', user_details);

      return { success, message, user };
    } catch (error) {
      throw error;
    }
  },

  async loginUser(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let options = {
        headers: { Accept: 'application/json' },
      };

      let {
        data: {
          message,
          success,
          data: { user },
        },
      } = await axios.post('/user/login', credentials, options);

      return { success, message, user };
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendPasswordResetLinkUser(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/user/password/email', credentials);

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async resetPasswordUser(ctx, data) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/user/password/reset', data);

      return { message, success };
    } catch (error) {
      throw error.response.data;
    }
  },

  async loginManager(ctx, credentials) {
    try {
      let options = {
        headers: { Accept: 'application/json' },
      };

      await axios.get('/sanctum/csrf-cookie');

      let {
        data: {
          message,
          success,
          data: { manager },
        },
      } = await axios.post('/manager/login', credentials, options);

      return { success, message, manager };
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendPasswordResetLinkManager(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/manager/password/email', credentials);

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async resetPasswordManager(ctx, data) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/manager/password/reset', data);

      return { message, success };
    } catch (error) {
      throw error.response.data;
    }
  },

  async registerCustomer(ctx, customer_details) {
    try {
      await axios.get('/sanctum/csrf-cookie');
      console.log('function incomplete');
      //
    } catch (error) {
      throw error.response.data;
    }
  },

  // WIP
  async loginCustomer(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');
      console.log('this function is incomplete');
      //
    } catch (error) {
      console.error(error);
      throw error.response.data;
    }
  },

  async sendPasswordResetLinkCustomer(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/customer/password/email', credentials);

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async resetPasswordCustomer(ctx, data) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/customer/password/reset', data);

      return { message, success };
    } catch (error) {
      throw error.response.data;
    }
  },

  async loginAffiliate(ctx, credentials) {
    try {
      let options = {
        headers: { Accept: 'application/json' },
      };

      let {
        data: {
          message,
          success,
          data: { affiliate_agent },
        },
      } = await axios.post('/affiliate/login', credentials, options);

      return { success, message, affiliate_agent };
    } catch (error) {
      throw error.response.data;
    }
  },

  async registerAffiliate(ctx, user_details) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: {
          success,
          message,
          data: { user },
        },
      } = await axios.post('/affiliate/register', user_details);

      return { success, message, user };
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendPasswordResetLinkAffiliate(ctx, credentials) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/affiliate/password/email', credentials);

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async resetPasswordAffiliate(ctx, data) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      let {
        data: { message, success },
      } = await axios.post('/affiliate/password/reset', data);

      return { message, success };
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default { state, getters, mutations, actions, namespaced };
