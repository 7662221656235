<template>
  <div>
    <breadcrumb :page="__('Business')" />

    <b-row>
      <b-col sm="12">
        <b-form @submit.prevent="handleSaveBusiness" class="pb-3" v-if="!fetching">
          <b-row>
            <b-col sm="12">
              <b-form-group :label="__('Business Name')">
                <b-form-input v-model="business.issuer_name" :placeholder="__('Enter business name...')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-form-group :label="__('Brand Name')">
                <b-form-input v-model="business.brand_name" :placeholder="__('Enter brand name...')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-form-group :label="__('Business Email')">
                <b-form-input v-model="business.issuer_email" :placeholder="__('Enter business email...')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-form-group :label="__('Business Phone Number')">
                <b-form-input v-model="business.issuer_phone" :placeholder="__('Enter business phone number...')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-button type="submit" variant="primary">
                {{ __('Save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>

        <div v-for="(errs, field) in errors" :key="field">
          <b-alert v-for="err in errs" :key="err" variant="danger" show>
            {{ err }}
          </b-alert>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      fetching: false,
      success: false,
      errors: {},
      message: '',
      business: {
        issuer_name: '',
        brand_name: '',
        issuer_email: '',
        issuer_phone: '',
      },
    };
  },

  async mounted() {
    this.business = await this.fetchBusinessSettings();
  },

  methods: {
    async fetchBusinessSettings() {
      this.fetching = true;
      try {
        let {
          data: {
            data: {
              enabled,
              settings: { value },
            },
          },
        } = await axios.get('/api/business_settings');
        return { enabled, ...value };
      } catch (err) {
      } finally {
        this.fetching = false;
      }
    },

    async handleSaveBusiness() {
      this.success = false;
      this.message = '';
      this.errors = {};
      let postData = { ...this.business, _method: 'put' };
      try {
        let {
          data: { message, success },
        } = await axios.post('/api/business_settings', postData);
        this.success = success;
        this.message = message;
      } catch (error) {
        this.success = false;
        this.message = error.response.data.message;
        this.errors = error.response.data.errors;
      } finally {
      }
    },
  },
};
</script>
