import moment from 'moment';

function formatDate(dates) {
  return dates === '' ? '-' : moment(dates).format('DD-MM-YYYY hh:mm:ss');
}

function getCurrentYear() {
  return moment().year();
}

function humanReadablePeriod(period) {
  return moment.duration(period).humanize();
}

function roundPrice(price) {
  price = (Math.round(price * 100) / 100).toFixed(2);

  return price;
}

function formatBytes(size) {
  const units = ['B', 'KB', 'MB', 'GB'];
  let index = 0;

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }

  return `${size.toFixed(2)} ${units[index]}`;
}

function showSuccessNotification(VueInstance, message, autoHideDelay = 5000) {
  VueInstance.$bvToast.toast(message, {
    title: __('Success'),
    variant: 'success',
    solid: true,
    autoHideDelay: autoHideDelay,
    headerClass: 'custom-toast-header-success',
    bodyClass: 'custom-toast-body-success',
  });
}

function showErrorNotification(VueInstance, message, title) {
  const h = VueInstance.$createElement;

  const errorMessages = Object.keys(message).map((field) => {
    const asterisk = h('span', { style: { color: 'red', marginRight: '4px' } }, ['*']);
    let text = '';
    let array = [];
    if (message[field].length > 1) {
      message[field].forEach(element => {
        text = h('div', {}, [asterisk, element]);
        array.push(text);
      });
      return h('div', {}, array);;
    } else {
      return h('div', {}, [asterisk, __(message[field])]);
    }
  });

  const vNodesMsg = h('div', {}, errorMessages);

  VueInstance.$bvToast.toast([vNodesMsg], {
    title: title,
    solid: true,
    noAutoHide: true,
    headerClass: 'custom-toast-header-error',
    bodyClass: 'custom-toast-body-error',
  });
}

function isExpiredSubscription(dates) {
  let expired = false;
  let endDate = new Date(dates);
  let currentDate = new Date();

  if (currentDate > endDate) {
    expired = true;
  }

  return expired;
}

function titleCase(str) {
  return str.replace(/^_*(.)|_+(.)/g, (str, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());
}

function truncateString(str, maxLength) {

  if (str && str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
}

function countCharacters(message) {
  let matched = message.match(/[\n\|\^\€\{\}\[\]\~]/g);
  return message.length + (matched ? matched.length : 0);
}

function countSegments(message, brand = '') {
  let count = 1;
  const length = countCharacters(message) + countCharacters(brand);

  if (length > 156) {
    count = Math.ceil((length) / 153);
  }

  return count;
}

export {
  formatDate,
  getCurrentYear,
  humanReadablePeriod,
  roundPrice,
  formatBytes,
  showSuccessNotification,
  showErrorNotification,
  isExpiredSubscription,
  titleCase,
  truncateString,
  countCharacters,
  countSegments
};
