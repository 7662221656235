<template>
  <b-tab :title="__('Onsend')">
    <Onsend />
  </b-tab>
</template>

<script>
import Onsend from './Settings';

export default {
  components: {
    Onsend,
  },
};
</script>
