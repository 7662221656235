import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
  faAdd,
  faArrowRightLong,
  faCaretDown,
  faCheck,
  faCopy,
  faDollarSign,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilePdf,
  faGaugeHigh,
  faGlobe,
  faHardDrive,
  faHouse,
  faImage,
  faLanguage,
  faLock,
  faPencil,
  faPhotoFilm,
  faPhone,
  faTrash,
  faUser,
  faExternalLink,
  faPaperPlane,
  faRefresh,
  faX,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faAdd, faArrowRightLong, faCaretDown, faCheck, faCopy, faDollarSign, faEnvelope, faEye, faEyeSlash, faFilePdf, faGaugeHigh, faGlobe, faHardDrive, faHouse, faImage, faLanguage, faLock, faPencil, faPhotoFilm, faPhone, faRefresh, faTrash, faUser, faExternalLink, faPaperPlane, faX, faFileAlt);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
